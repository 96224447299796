import React, { useState, useEffect } from "react";
import { Content } from "antd/es/layout/layout";

import {
  Card,
  Radio,
  Modal,
  Form,
  Input,
  Typography,
  Flex,
  Button,
  Spin,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./style.css";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFullName } from "../Services/authServices";
import { fetchUserAttributes } from "@aws-amplify/auth";
import axios from "axios";
import { InputNumber } from "antd";
import canadaFlag from "../Image/flag/cad_flag.png";
import { useTranslation } from "react-i18next";

const FundWallet = () => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const walletDetails = location.state.wallet;
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState("");

  const [user, setUser] = useState([]);
  const [fullName, setFullName] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const navigate = useNavigate();

  const handleCardClick = (value) => {
    setSelectedPaymentMethod(value);
  };

  async function currentAuthenticatedUser() {
    try {
      const user = await fetchUserAttributes();
      setUser(user);
      const full_name = await getFullName();
      //console.log("full_name", full_name);
      setFullName(full_name);
      return user;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  useEffect(() => {
    currentAuthenticatedUser();
    console.log("Test state:", location.state);
  }, []);

  async function currentAuthenticatedUser() {
    try {
      const user = await fetchUserAttributes();
      setUser(user);
      const full_name = await getFullName();
      //console.log("full_name", full_name);
      setFullName(full_name);
      return user;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  console.log(form.getFieldsValue());
  const handleSubmit = async () => {
    // Check if a payment method is selected
    if (!selectedPaymentMethod) {
      setPaymentMethodError("Please select a payment method");
      return; // Stop the function execution if no payment method is selected
    } else {
      setPaymentMethodError(""); // Clear the error message if a payment method is selected
    }

    form
      .validateFields()
      .then(async (values) => {
        setLoading(true); // Start loading only after passing the initial checks
        console.log("Raw amount:", values.amount); // Log the raw amount value
        const rawAmount = values.amount.replace(/\$\s?|(,*)/g, ""); // Remove '$' and commas from the amount string
        console.log("Raw amount (after parsing):", rawAmount); // Log the parsed raw amount value
        const amountCAD = parseFloat(rawAmount).toFixed(2); // Convert the amount to a floating point number and fix it to 2 decimal places
        try {
          const payload = {
            CustomerEmail: user.email,
            CustomerName: fullName,
            Description: "Adding Funds to Wallet",
            walletID: walletDetails.id,
            userID: walletDetails.userID,
            Amount: amountCAD,
            status: "Pending",
            paymentMethod: selectedPaymentMethod,
          };

          console.log("Sending payload to Lambda:", payload);

          const lambdaResponse = await axios.post(
            "https://40h1ly2xae.execute-api.us-east-2.amazonaws.com/default/AddFundToWallet-dev",
            payload
          );
          console.log("Lambda response:", lambdaResponse);

          // Conditional confirmation content based on the selected payment method
          if (selectedPaymentMethod === "interac") {
            setConfirmationContent(
              "An Interac payment link has been sent to your email. Please proceed there to add funds."
            );
          } else {
            setConfirmationContent(
              "Your request to add funds has been recorded. You will receive an email shortly with further instructions."
            );
          }
          setShowConfirmation(true);
        } catch (err) {
          console.error("Error creating request:", err);
        } finally {
          setLoading(false); // Stop loading regardless of the result
        }
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
        // No need to set loading to false here since it was never set to true if form validation failed
      });
  };

  const paymentMethods = [
    {
      title: "Bill Payment",
      value: "Bill Payment",
      //description: "Use your Wallet for payment",
    },
    {
      title: "EFT",
      value: "EFT",
      //description: "Use your Wallet for payment"
    },
    {
      title: "Cash",
      value: "Cash",
      //description: "Use your Wallet for payment",
    },
    {
      title: "Interac",
      value: "interac",
      //description: "Use Interac for payment",
    },
  ];

  const handleBackToWallet = () => {
    setShowConfirmation(false);
    navigate("/wallet"); // Assuming '/wallet' is your wallet page route
  };
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
        <h1 style={{ color: "#3D49AB" ,fontFamily: 'Gopher'}}>{t("dashboard.AddFund")}</h1>
        </div>
        <hr />
    
        <Typography.Paragraph
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "1rem",
            fontWeight: 500,
            fontFamily: 'Gopher'
          }}
        >
          {t("SendMoney.Payment_Method")}
          {/* Assuming you have the relevant text in your translation files */}
        </Typography.Paragraph>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {paymentMethods.map((method) => (
            <Card
              key={method.value}
              hoverable
              className={`card-hover-effect-addfund ${
                selectedPaymentMethod === method.value ? "selected" : ""
              }`} // Apply the 'selected' class based on state
              style={{
                width: "100%",
                maxWidth: "150px",
                textAlign: "center",
                fontFamily: 'Gopher'
              }}
              onClick={() => handleCardClick(method.value)}
            >
              <Card.Meta
                title={method.title}
                description={method.description}
              />
            </Card>
          ))}
        </div>

        <Typography.Paragraph
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "1rem",
            marginTop: "20px",
            fontWeight: 500,
            fontFamily: 'Gopher'
          }}
        >
          {t("SendMoney.Amount")}
          {/* Assuming you have the relevant text in your translation files */}
        </Typography.Paragraph>
        <Form.Item
          name="amount"
          style={{
            marginTop: "20px",
          }}
          rules={[
            {
              required: true,
              message: "Please input the amount you want to add!",
            },
          ]}
        >
          <Flex style={{ alignItems: "center", width: "100%" }}>
            <div
              className="input-addon"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
                fontSize: "16px",
              }}
            >
              <img
                src={canadaFlag}
                alt="CAD"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <InputNumber
              size="large"
              className="input-field"
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ flex: 1, height: "40px", borderRadius: "0.5rem" }} // You can set the height to match your design
              placeholder={t("SendMoney.Amount")} // Now uses the translation for "Amount" as the placeholder
            />
          </Flex>
        </Form.Item>

        {paymentMethodError && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <p style={{ color: "red" }}>{paymentMethodError}</p>
          </div>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {loading ? <Spin /> : t("EditRecipient.Continue")}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Confirmation"
        visible={showConfirmation}
        onCancel={handleBackToWallet}
        closable={true}
        footer={[
          <Button key="back" onClick={handleBackToWallet}>
            Back to Wallet
          </Button>,
        ]}
      >
        {loading ? (
          <Spin tip="Loading...">{confirmationContent}</Spin>
        ) : (
          <p>{confirmationContent}</p>
        )}
      </Modal>
    </Content>
  );
};
export default FundWallet;